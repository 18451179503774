import { useEffect, useState } from 'react';
import cx from 'classnames';
import { formatDateTimeStringWithAgo } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import Grid, { Break, EmptyRow, Label, Value } from './PricingGrid';
import { useSetFinalPremiumOverridesMutation, useRepriceMutation } from '../../services/apiSlice';
import { useAppContext } from '../../hooks/context';
import { useQuoteId, usePricingIsDirty, useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import { useForm } from 'react-hook-form';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';
import { useSubmissionIsEditable } from '../../hooks/submissions';
import { getAmountSubjectRate, getTivRate, TwButton } from './utils';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { PRICING_DEBOUNCE_TIME } from '../../constants/PricingConstants';
import { isNumber, isString } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { useChangeEffect, useDebounceCallback } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { PolicyCoverageType } from '../../ts-types/ApiTypes';
import { BlurSubmitFormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/BlurSubmitFormInput';
import { ErrorTooltip, OverridenTooltip } from '../common/CommonTooltips';

const isCarrierAllocationDefault = (carrierData: Record<string, any>[]) => {
    let isDefault = true;
    carrierData?.forEach(({ default_participation_pct, final_participation_pct }) => {
        if (default_participation_pct !== final_participation_pct) {
            isDefault = false;
        }
    });
    return isDefault;
};

const PRICING_STATUS = {
    LOADING: 'Loading...',
    RECALCULATING: 'Recalculating...',
    SUCCESS: 'Success',
    ERROR: 'Error',
    UNKNOWN: 'Unknown',
};

type PricingStatusType = keyof typeof PRICING_STATUS;

const PRICING_STATUS_ICON = {
    LOADING: null,
    RECALCULATING: null,
    SUCCESS: 'done',
    ERROR: 'error',
    UNKNOWN: null,
};

const PricingStatus = () => {
    const quoteId = useQuoteId();
    const { finalPremium, isLoading, isFetching, isUninitialized } = useQuoteFinalPremium();
    const [_setFPOverrides, { isLoading: isOverriding }] = useSetFinalPremiumOverridesMutation({ fixedCacheKey: `${quoteId}` });
    const [_triggerReprice, { isLoading: isRepricing }] = useRepriceMutation({ fixedCacheKey: `${quoteId}` });
    const isValid = !!finalPremium?.is_valid;

    let pricingStatus: PricingStatusType;
    if (isLoading && !finalPremium) {
        pricingStatus = 'LOADING';
    } else if (isFetching || isUninitialized || isOverriding || isRepricing) {
        pricingStatus = 'RECALCULATING';
    } else if (!isValid) {
        pricingStatus = 'ERROR';
    } else if (isValid) {
        pricingStatus = 'SUCCESS';
    } else {
        pricingStatus = 'UNKNOWN';
    }

    const icon = PRICING_STATUS_ICON[pricingStatus];
    return (
        <div className="tw-flex tw-gap-x-0.5">
            <p
                className={cx('tw-text-[0.95rem]', {
                    'tw-text-red-600': icon === PRICING_STATUS_ICON['ERROR'],
                    'tw-text-green-600': icon === PRICING_STATUS_ICON['SUCCESS'],
                })}
            >
                {PRICING_STATUS[pricingStatus]}
            </p>
            {icon && (
                <i
                    className={cx('material-icons tw-text-[0.95rem]', {
                        'tw-self-center': icon === PRICING_STATUS_ICON['ERROR'],
                        'tw-text-red-600': icon === PRICING_STATUS_ICON['ERROR'],
                        'tw-text-green-600': icon === PRICING_STATUS_ICON['SUCCESS'],
                    })}
                >
                    {icon}
                </i>
            )}
            {(pricingStatus === 'RECALCULATING' || pricingStatus === 'LOADING') && (
                <div className="tw-flex tw-items-center tw-justify-center tw-p-0 tw-w-full large">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

const MAP_CLEAN_DATA_FOR_SUBMISSION = {
    agent_commission_rate: (data: string) => (+data / 100).toFixed(3),
    inspection_fees_override: (data: string) => (+data).toFixed(0),
    charged_premium_before_tf: (data: string) => (+data).toFixed(0),
    gross_amount_subject_account_rate: (data: string, amount_subject: string) => ((+data * +amount_subject) / 100).toFixed(0),
    gross_tiv_account_rate: (data: string, tiv: string) => ((+data * +tiv) / 100).toFixed(0),
};

const MAP_OVERRIDES_FOR_DISPLAY = {
    agent_commission_rate: (data: unknown) => (isString(data) && data ? (+data * 100).toFixed(1) : ''),
    inspection_fees_override: (data: unknown, fallback: string) => (isString(data) && data ? (+data).toFixed(0) : fallback),
    charged_premium_before_tf: (data: unknown) => (isString(data) && data ? (+data).toFixed(0) : ''),
    gross_amount_subject_account_rate: (data: unknown) => (isNumber(data) ? data.toFixed(2) : ''),
    gross_tiv_account_rate: (data: unknown) => (isNumber(data) ? data.toFixed(2) : ''),
};

const getFormDefaults = (policyCoverage: PolicyCoverageType | null, fallbackInspectionFees: string) => {
    const entries = Object.keys(MAP_OVERRIDES_FOR_DISPLAY).map((key: keyof typeof MAP_OVERRIDES_FOR_DISPLAY) => {
        const mapperFn = MAP_OVERRIDES_FOR_DISPLAY[key];
        const dataToDisplay = policyCoverage?.[key] ?? '';
        return [key, mapperFn(dataToDisplay, key === 'inspection_fees_override' ? fallbackInspectionFees : undefined)];
    });
    // TODO: Use wrapper to get type
    return Object.fromEntries(entries) as FormValues;
};

type FormValues = { [P in keyof typeof MAP_OVERRIDES_FOR_DISPLAY]: string };
type SubmitValues = { [P in keyof typeof MAP_OVERRIDES_FOR_DISPLAY]: ReturnType<(typeof MAP_OVERRIDES_FOR_DISPLAY)[P]> } & {
    gross_amount_subject_account_rate: number;
    gross_tiv_account_rate: number;
};

export const PricingPageHeader = () => {
    const quoteId = useQuoteId();
    const { data, finalPremium } = useQuoteFinalPremium();
    const lastPricedData = finalPremium?.last_successfully_priced_on || '';
    const lastModeledData = data?.last_cat_model_request_sent_on || '';
    const [lastPriced, setLastPriced] = useState('');
    const [lastModeled, setLastModeled] = useState('');
    const { currentSubmission, featureFlags } = useAppContext();
    const isEditable = useSubmissionIsEditable();
    const { policyCoverage } = useQuotePolicyCoverage();
    const [setOverrides] = useSetFinalPremiumOverridesMutation({ fixedCacheKey: `${quoteId}` });
    const debouncedSetOverrides = useDebounceCallback((data: SubmitValues) => setOverrides({ id: finalPremium.id, quoteId, data }), PRICING_DEBOUNCE_TIME);
    const disabled = !isEditable || !finalPremium || !policyCoverage;
    const isValid = !!finalPremium?.is_valid;

    useEffect(() => {
        const updateDateFields = () => {
            setLastPriced(lastPricedData ? formatDateTimeStringWithAgo(lastPricedData) : '');
            setLastModeled(lastModeledData ? formatDateTimeStringWithAgo(lastModeledData) : '');
        };
        updateDateFields();
        // update formatted date strings every minute
        const interval = setInterval(() => updateDateFields(), 60 * 1000);
        return () => clearInterval(interval);
    }, [lastPricedData, lastModeledData]);

    const [pricingIsDirty, refreshPricing] = usePricingIsDirty(quoteId);

    const {
        amount_subject,
        charged_premium_before_tf_net_of_agent_commission,
        final_premium_after_taxes,
        final_premium_after_taxes_net_of_agent_commission,
        inspection_fees,
        inspection_fees_final,
        modeling_fee_final,
        net_amount_subject_account_rate,
        net_tiv_account_rate,
        technical_premium_with_wdbd,
        technical_premium_with_wdbd_net_of_agent_commission,
        target_premium_after_wdbd_net_of_agent_commission,
        target_premium_after_wdbd,
        tiv,
        total_premium_override,
    } = finalPremium ?? {};
    const { default_agent_commission_rate } = policyCoverage ?? {};

    const formDefaultValues: FormValues = getFormDefaults(policyCoverage, inspection_fees);
    const { handleSubmit, control, resetField, formState } = useForm<FormValues>({
        shouldFocusError: true,
        defaultValues: formDefaultValues,
    });

    const { dirtyFields, errors } = formState;

    // Reset override default values when changed via input form or via external event
    // Ensure that we only reset fields that have changed to avoid wiping out user input
    // Note: we have extra isDirty checks in here because the final premium can change our default value for inspection fees
    useChangeEffect(formDefaultValues, (nextDefaults, prevDefaults) => {
        Object.keys(nextDefaults).forEach((key: keyof FormValues) => {
            if (!prevDefaults || prevDefaults[key] !== nextDefaults[key]) {
                resetField(key, { defaultValue: nextDefaults[key] });
            }
        });
    });

    const submitHandler = async (data: FormValues) => {
        const cleanedSubmitData = {};
        const dirtyData = Object.fromEntries(Object.entries(data).filter(([key, _value]) => key in dirtyFields));
        if (Object.keys(dirtyData).length === 0) {
            return;
        }
        Object.keys(dirtyData).forEach(key => {
            const targetValue = data[key]?.replace(/,/g, '') ?? '';
            if (key.startsWith('gross_')) {
                const denominator = key === 'gross_tiv_account_rate' ? tiv : amount_subject;
                cleanedSubmitData['charged_premium_before_tf'] = targetValue !== '' ? MAP_CLEAN_DATA_FOR_SUBMISSION[key](targetValue, denominator) : null;
            } else if (key === 'inspection_fees_override' && data[key] === '' && policyCoverage.inspection_fees_override === null) {
                // handle edge case where inspection fees is displaying fallback of "250.0" and we attempt to submit null again
                resetField('inspection_fees_override');
                return;
            } else {
                cleanedSubmitData[key] = targetValue !== '' ? MAP_CLEAN_DATA_FOR_SUBMISSION[key](targetValue) : null;
            }
        });
        // TODO: Remove after VENT-12328
        // While doing optimistic update for setFinalPremiumOverrides, we don't have access to tiv and amount_subject
        // As a hack, let's just send the calculated value to be used in the optimistic update
        // These rate values are ignored in the actual request payload
        if (cleanedSubmitData['charged_premium_before_tf'] !== undefined) {
            const premium = cleanedSubmitData['charged_premium_before_tf'];
            cleanedSubmitData['gross_amount_subject_account_rate'] = premium !== null ? getAmountSubjectRate(premium, amount_subject) : '';
            cleanedSubmitData['gross_tiv_account_rate'] = premium !== null ? getTivRate(premium, tiv) : '';
        }
        if (Object.keys(cleanedSubmitData).length === 0) {
            return;
        }
        debouncedSetOverrides({ ...(cleanedSubmitData as SubmitValues) });
    };

    return (
        <>
            <div className="viki-status pricing-header">
                <Grid columns={2} className="gap-0125">
                    <EmptyRow width={2} />

                    <Label>TIV</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={tiv} />

                    <Label>Amount Subject</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={amount_subject} />

                    <Break width={2} />

                    <Label className="sub-header grid-2-wide">Broker Commission Rate</Label>

                    <Label>Default</Label>
                    <Value
                        format={{ type: 'number', decimals: 1, postfix: '%' }}
                        value={default_agent_commission_rate ? Number(default_agent_commission_rate) * 100 : ''}
                    />

                    <Label className="self-center">This Account</Label>
                    <div className="justify-self-end tw-flex tw-items-center">
                        <ErrorTooltip error={errors?.agent_commission_rate?.message} />
                        <BlurSubmitFormInput<FormValues>
                            className="tw-max-w-[45px] pricing-row-height"
                            disabled={disabled}
                            control={control}
                            name="agent_commission_rate"
                            rules={{ required: 'Must provide value for Agent Commission Rate' }}
                            onSubmit={handleSubmit(submitHandler)}
                            onReset={() => resetField('agent_commission_rate')}
                            maskOptions={{ ...MASKS['PERCENTAGE_POSITIVE'], numeralIntegerScale: 2, numeralDecimalScale: 1 }}
                            percentAfter
                        />
                    </div>

                    <Break width={2} />

                    <Label>Carrier Allocation</Label>
                    <Value value={isCarrierAllocationDefault(data?.final_carrier_data) ? 'Default' : 'Not Default'} />
                </Grid>
            </div>

            <div className="viki-status pricing-header">
                <Grid columns={3} className="gap-0125">
                    <Label />
                    <Value value="Net" className="sub-header" />
                    <Value value="Gross" className="sub-header" />

                    {featureFlags?.enable_wind_deductible_buy_down && (
                        <>
                            <Label>Technical Premium</Label>
                            <Value format={{ type: 'number', delimiter: ',' }} value={technical_premium_with_wdbd_net_of_agent_commission} />
                            <Value className="" format={{ type: 'number', delimiter: ',' }} value={technical_premium_with_wdbd} />
                        </>
                    )}

                    <Label>Target Premium</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={target_premium_after_wdbd_net_of_agent_commission} />
                    <Value className="" format={{ type: 'number', delimiter: ',' }} value={target_premium_after_wdbd} />

                    {total_premium_override && (
                        <>
                            <Label className="grid-2-wide flex ai_c gap-x-1">
                                Charged Premium Before Terror
                                {!disabled && (
                                    <Tooltip
                                        wrapChild={false}
                                        content={`
                                    Charged Premium Before Terror and Fees is no longer used.
                                    Set the Charged Premium Before Fees field below to remove the old override.
                                    `}
                                    >
                                        <span className="cursor-pointer material-icons red-txt text-sm-no-lh">warning</span>
                                    </Tooltip>
                                )}
                            </Label>
                            <Value className="" format={{ type: 'number', delimiter: ',' }} value={total_premium_override} />
                        </>
                    )}

                    <Label>Charged Premium Before Fees</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={charged_premium_before_tf_net_of_agent_commission} />
                    <BlurSubmitFormInput<FormValues>
                        className="tw-max-w-[65px] pricing-row-height"
                        disabled={disabled}
                        control={control}
                        name="charged_premium_before_tf"
                        onSubmit={handleSubmit(submitHandler)}
                        onReset={() => resetField('charged_premium_before_tf')}
                        maskOptions={MASKS['CURRENCY_INTEGER']}
                        rightAlignText
                    />

                    <Label>Rate (TIV)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={net_tiv_account_rate} />
                    <BlurSubmitFormInput<FormValues>
                        className="tw-max-w-[65px] pricing-row-height"
                        disabled={!tiv || disabled}
                        control={control}
                        name="gross_tiv_account_rate"
                        onSubmit={handleSubmit(submitHandler)}
                        onReset={() => resetField('gross_tiv_account_rate')}
                        maskOptions={{ ...MASKS['PERCENTAGE_POSITIVE'], numeralDecimalScale: 2, delimiter: '' }}
                        rightAlignText
                    />

                    <Label>Rate (Amount Subject)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={net_amount_subject_account_rate} />
                    <BlurSubmitFormInput<FormValues>
                        className="tw-max-w-[65px] pricing-row-height"
                        disabled={!amount_subject || disabled}
                        control={control}
                        name="gross_amount_subject_account_rate"
                        onSubmit={handleSubmit(submitHandler)}
                        onReset={() => resetField('gross_amount_subject_account_rate')}
                        maskOptions={{ ...MASKS['PERCENTAGE_POSITIVE'], numeralDecimalScale: 2, delimiter: '' }}
                        rightAlignText
                    />

                    <Label className="self-center">Inspection Fees</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={inspection_fees_final} />
                    <div className="tw-flex tw-justify-self-end">
                        <OverridenTooltip modelValue={+inspection_fees} overrideValue={+policyCoverage.inspection_fees_override} />
                        <BlurSubmitFormInput<FormValues>
                            className="tw-max-w-[65px] pricing-row-height"
                            disabled={!amount_subject || disabled}
                            control={control}
                            name="inspection_fees_override"
                            onSubmit={handleSubmit(submitHandler)}
                            onReset={() => resetField('inspection_fees_override')}
                            maskOptions={MASKS['CURRENCY_INTEGER']}
                            rightAlignText
                        />
                    </div>

                    <Label>Modeling Fees</Label>
                    <Value format={{ type: 'number' }} value={modeling_fee_final} />
                    <Value format={{ type: 'number' }} value={modeling_fee_final} />

                    <If condition={currentSubmission?.account_purpose !== 'AC'}>
                        <Label>Charged Premium After Fees</Label>
                        <Value format={{ type: 'number', delimiter: ',' }} value={final_premium_after_taxes_net_of_agent_commission} />
                        <Value format={{ type: 'number', delimiter: ',' }} value={final_premium_after_taxes} />
                    </If>

                    <If condition={currentSubmission?.account_purpose === 'AC'}>
                        <br />
                        <div style={{ textAlign: 'left' }} className="red-txt grid-3-wide">
                            To set the charged premium, use the Endorsement Pricing button after moving to In-Review.
                        </div>
                    </If>
                </Grid>
            </div>

            <div className="viki-status pricing-header tw-overflow-hidden">
                <Grid columns={2} className="gap-0125">
                    <Label className="tw-text-[0.95rem] tw-font-semibold">Pricing Results</Label>
                    <PricingStatus />

                    <EmptyRow width={2} />

                    <Label className="min-w-full">Last Priced:</Label>
                    <Value className="tw-text-left" value={lastPriced} />

                    <Label className="min-w-full">Last Modeled:</Label>
                    <Value className="tw-text-left" value={lastModeled} />

                    {(pricingIsDirty || !isValid) && <Break width={2} />}

                    {pricingIsDirty && (
                        <>
                            <Label className="grid-2-wide tw-font-semibold">New Pricing Data Available</Label>
                            <TwButton className="grid-2-wide tw-w-1/2 tw-my-1" onClick={() => refreshPricing()}>
                                Refresh
                            </TwButton>
                        </>
                    )}

                    {!isValid && (
                        <>
                            <Label className="min-w-full sub-header grid-2-wide red">Pricing Error</Label>
                            <Label className="min-w-full grid-2-wide red">{finalPremium?.error_message}</Label>
                        </>
                    )}
                </Grid>
            </div>
        </>
    );
};
